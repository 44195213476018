:root {
  --blue-cta: #528de5;
}

html {
  scroll-behavior: smooth;
  margin: 0;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  padding: 0;
  top: 0 !important;
}

body > .skiptranslate {
  display: none !important;
}

.mainContent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.form-helper-error-text {
  color: yellow;
  font-size: 11px;
  font-weight: 600;
  text-transform: capitalize;
}
/* Home > Projects */
.swiper-slide-active {
  width: 500px !important;
  /* border: 2px solid yellow; */
}

.swiper-slide {
  /* width: 150px; */
  padding: 20px;
  border-left: 2px solid #e1e8ed;
}
.swiper-slide.swiper-slide-active .project-heading p {
  display: block;
}

.swiper-slide .project-heading p {
  display: none;
}
.swiper-button-prev::after {
  content: "" !important;
}
.swiper-button-next::after {
  content: "" !important;
}
.sliderControlIcon {
  width: 32px;
  height: 32px;
  color: white;
  flex-shrink: none;
}

.project-cover {
  height: 300px;
  box-sizing: border-box;
}

.project-heading h5 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.project-heading p {
  font-size: 19px;
  text-transform: capitalize;
}

.project-cover img {
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
  object-fit: cover;
  object-position: center;
}
.projects_container {
  padding: 6rem 1rem;
  background-color: #222222;
  color: #ffffff;
  position: relative;
}
.projects_head {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project_title {
  font-size: 50px;
  text-align: center;
}

@media (min-width: 768px) {
  .project_title {
    font-size: 72px;
  }
}

#projectSliderModal .sliderControlIcon {
  color: #141414;
}
#projectSliderModal .modal-content {
  border-radius: 12px;
  overflow: hidden;
}
.carousel-item.next,
.carousel-item-next,
.carousel-item.prev,
.carousel-item-prev {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 16px;
}
.project-slider-modal-item.active {
  padding: 16px;
  display: inline-flex;
  flex-wrap: wrap;
  color: #0a1d24;
  width: 100%;
  background-color: #fff;
}

.project-slider-modal-item .project-cover {
  width: 50%;
  flex-shrink: 0;
}
.project-slider-modal-item .project-heading {
  padding: 0 12px;
  width: 50%;
}
.project-slider-modal-item .project-heading > p {
  font-size: 18px;
  text-transform: uppercase;
}

.project-slider-modal-item .project-heading .project-footer p {
  font-size: 12px;
}
@media (max-width: 768px) {
  .project-slider-modal-item .project-cover {
    width: 100%;
  }
  .project-slider-modal-item .project-heading {
    width: 100%;
  }
}

/* Ends: Home > Projects */

.word_slider {
  position: relative;
  display: inline-block; /* Want to keep content in the same line*/
  /* height: 50px; */
  /* overflow: hidden; */
  width: 100%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.word_slide {
  position: absolute;
  display: inline-block;
  background: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  opacity: 0;
  transform: translateY(100%); /* Start off-screen */
  -webkit-animation: word_slide 6s infinite;
          animation: word_slide 6s infinite; /* Total duration for all slides */
}

@-webkit-keyframes word_slide {
  0%,
  10% {
    opacity: 0;
    transform: translateY(0); /* Slide in */
  }
  30%,
  40% {
    opacity: 1; /* Stay visible */
    transform: translateY(0);
  }
  50%,
  60% {
    opacity: 0; /* Fade out */
    /* transform: translateY(-100%); */
    /* Slide out */
  }
}

@keyframes word_slide {
  0%,
  10% {
    opacity: 0;
    transform: translateY(0); /* Slide in */
  }
  30%,
  40% {
    opacity: 1; /* Stay visible */
    transform: translateY(0);
  }
  50%,
  60% {
    opacity: 0; /* Fade out */
    /* transform: translateY(-100%); */
    /* Slide out */
  }
}

/* Delay each slide to create a sequential effect */
.word_slide:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.word_slide:nth-child(2) {
  -webkit-animation-delay: 2s;
          animation-delay: 2s; /* Starts after first slide */
}
.word_slide:nth-child(3) {
  -webkit-animation-delay: 4s;
          animation-delay: 4s; /* Starts after second slide */
}

.row {
  margin: 0;
  padding: 0;
}
section {
  height: 100vh;
}
.loading {
  height: 100vh;
}
.navbar-dark {
  background: linear-gradient(
    180deg,
    rgba(89, 89, 89, 0.3) 0,
    rgba(89, 89, 89, 0.08) 40.97%,
    rgba(89, 89, 89, 0) 100%
  );
}
#mainNav {
  background: #0a1d24a8;
  padding-bottom: 1rem;
  padding-top: 2rem;
}
#mainNav .navbar-toggler {
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.75rem;
  text-transform: uppercase;
}
#mainNav .navbar-nav:first-of-type {
  border-bottom: 5px solid rgba(255, 255, 255, 0);
}
#mainNav .navbar-nav .nav-item .nav-link {
  color: #fff;
  font-size: 0.95rem;
  letter-spacing: 0.0625em;
  opacity: 0.7;
  transition: 0.2s ease;
}
#mainNav ul {
  border-bottom: 12px solid rgba(255, 255, 255, 0);
}
ul.navbarBorder {
  position: relative;
}
.navbarBottomBorder {
  width: 100%;
  height: 0.04rem;
  position: absolute;
  left: 0;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #fff;
  z-index: 2;
  top: 33.5px;
}
#mainNav .navbar-nav .nav-item .nav-link.active,
#mainNav .navbar-nav .nav-item .nav-link:hover {
  opacity: 1;
  padding-bottom: 2px;
}
.nav-item > .active {
  border-bottom: 5px solid #fff;
}
/*HEADER*/
header.masthead {
  color: #fff;
  background-image: url(/static/media/Home.7095c7c3.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  padding: 7rem 7rem 6rem;
  text-align: center;
}
header.masthead .masthead-subheading {
  font-size: 0.6rem;
  line-height: 1rem;
  margin-bottom: 5px;
}
header.masthead .masthead-heading {
  font-size: 3.25rem;
  line-height: 3.25rem;
  letter-spacing: 0.1em;
  margin-bottom: 1rem;
}
header img {
  width: 38%;
}
header div.container {
  text-align: left;
}
@media (min-width: 768px) {
  header.masthead {
    padding: 8rem 15rem 12.5rem;
  }

  header.masthead .masthead-subheading {
    font-size: 0.8rem;
    line-height: 1rem;
    margin-bottom: 0.1rem;
  }
  header.masthead .masthead-heading {
    font-size: 3rem;
    font-weight: 400;
    line-height: 3rem;
    letter-spacing: 0.05em;
    margin-bottom: 3rem;
  }
}
header img.arrowDown {
  cursor: pointer;
  transition: 0.3s ease-in-out;
  width: 2rem;
}
header img.arrowDown:hover {
  opacity: 0.5;
}
.flagContainer {
  margin-top: 0.6rem;
}
.flagContainer p {
  margin-top: 0.1rem;
}
.flagDiv {
  color: #fff;
  cursor: pointer;
  -webkit-filter: saturate(0);
          filter: saturate(0);
  opacity: 0.8;
  transition: 0.2s ease-in-out;
}
.flag {
  height: 20px;
  width: 20px;
  margin-top: 4px;
}
.flagDiv:hover,
.coloriza {
  color: #fff;
  -webkit-filter: saturate(1);
          filter: saturate(1);
  opacity: 1;
  text-decoration: none;
}
.paddingXaxis {
  padding: 17rem 9.5rem 12.5rem 15rem;
}
.btn-contact {
  border: 1px solid #fff;
  transition: 0.2s ease-in-out;
}
.btn-contact:hover {
  background-color: rgba(255, 255, 255, 0.404) !important;
  border: 1px solid #fff;
  color: #fff;
}
.divisionLine {
  background: #0c2029;
  height: 3px;
  width: 50px;
}
.titleHighlighted {
  background-color: #fff;
  color: #0c2029;
  display: inline-block;
  height: 48px;
  letter-spacing: 0.15em;
}
header div.container {
  border-bottom: 1px solid #fff;
}
.sectionPadding {
  padding: 6rem 15rem 1rem;
}
.sectionPadding1 {
  padding: 2rem 15rem 0;
}
.sectionPadding2 {
  padding: 6rem 15rem 0;
}

.fjalla {
  font-family: "Fjalla One", sans-serif !important;
}
.sectionTitle {
  font-size: 3rem;
  letter-spacing: 0.03em;
}
.sectionSubtitle {
  font-size: 1.5rem;
  letter-spacing: 0.1em;
}
.sectionDescription {
  font-size: 0.87rem;
  letter-spacing: 0.1em;
  line-height: 1.1rem;
  width: 60%;
}
.txt-black {
  color: #313131;
  opacity: 0.7;
}
.btn-custom {
  border-radius: 30px;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.03em;
  line-height: 1.1rem;
  padding: 0.5rem 1.2rem;
  text-transform: uppercase;
}
.btn-blue-cta {
  color: #fff;
  background: #528de5;
  background: var(--blue-cta);
  transition: all 0.3s ease-in-out;
}
.goog-text {
  vertical-align: middle !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-transparent {
  background: transparent;
  border: 1px solid #fff;
  transition: all 0.3s ease-in-out;
}
.btn-blue-cta:hover,
.btn-transparent:hover {
  background: #fff;
  box-shadow: 0 8px 23px -1px #999;
}
.goog-te-banner-frame.skiptranslate {
  display: none;
}

/* INFO */

#carouselExampleFade {
  height: 60vh;
}
.carousel-control {
  width: 30%;
}
.carousel-indicators li {
  background-color: #c4c4c4;
  border-radius: 100%;
  height: 10px;
  width: 10px;
}
.infoCarousel {
  font-size: 3rem;
  text-align: center;
  line-height: 3rem;
  letter-spacing: 0.08em;
  color: #1d416e;
}
.carouselArrow {
  color: #1d416e;
}
.infoDiv {
  background-color: #1d416e;
  text-align: center;
  cursor: pointer;
}
.infoDiv h3 {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  margin-bottom: 0.6rem;
}
.borderR {
  border-right: 2px solid #fff;
}
.infoDiv p {
  font-size: 0.7rem;
  letter-spacing: 0.08em;
  line-height: 15px;
}
.infoItem {
  opacity: 0.7;
  transition: 0.3s ease-in-out all;
}

.infoItem i {
  font-size: 2rem;
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 1rem;
}

/* TRANKS */

.thanksDiv {
  background: linear-gradient(180deg, #1d416e 5.19%, rgba(46, 73, 107, 0) 100%),
    url(/static/media/Agradecimiento.ba89cb45.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center 40%;
  background-size: cover;
  height: 60%;
}
.thanksItem {
  background-color: #2e496b;
  border-radius: 100px;
}
.thanksItem i {
  font-size: 4rem;
  padding: 2rem 1.5rem;
}
.thanksItem2 p {
  font-size: 0.7rem;
  line-height: 18px;
  letter-spacing: 0.1em;
}
.thanksDescriptionDiv ul {
  list-style: none;
  font-size: 0.7rem;
  line-height: 18px;
  letter-spacing: 0.1em;
}
.thanksDescriptionDiv ul i {
  font-size: 0.5rem;
  color: #528de5;
  color: var(--blue-cta);
}

/* SERVICES 1*/

.services1 {
  -webkit-clip-path: polygon(50% 0%, 100% 20%, 100% 100%, 0 100%, 0% 20%);
          clip-path: polygon(50% 0%, 100% 20%, 100% 100%, 0 100%, 0% 20%);
  background-color: #ededed;
  height: 100vh;
}
.serviceItem {
  background-color: transparent;
  border-radius: 15px;
  box-shadow: 0 8px 10px 2px rgba(0, 0, 0, 0);
  transition: 0.3s ease-in-out all;
  text-decoration: none;
}
.serviceItem:hover {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 8px 10px 2px rgba(0, 0, 0, 0.15);
}
.serviceItem h4 {
  font-weight: bolder;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.1em;
}
.serviceItem p {
  font-size: 0.7rem;
  line-height: 1.1rem;
  letter-spacing: 0.1em;
}

/* SERVICES 2*/

.services2 {
  background-color: #ededed;
  height: 55%;
}
.service2Item {
  text-align: center;
  transition: 0.3s ease-in-out all;
}
.service2Item:hover {
  cursor: pointer;
}
/* SERVICES BY TYPE START */

.service2-active .service2Shape {
  position: relative;
  background-color: rgb(214, 214, 214) !important;
  color: #313131;
  -webkit-filter: saturate(1);
          filter: saturate(1);
  opacity: 1;
  transition: 0.5s ease-in-out all;
  z-index: 5;
}

.service2-active .shape-industrial {
  background-color: #f48c06 !important;
}

.service2-active .shape-hotelero {
  background-color: rgb(255, 255, 255) !important;
}

.service2-active .shape-empresarial {
  background-color: #3d74b8 !important;
}

.service2-active .shape-arq {
  background-color: #e9ac3d !important;
}

.service2-active .shape-hogar {
  background-color: #93abd8 !important;
}

.service2-active .service2Name {
  background-color: rgba(255, 255, 255, 0.568);
  margin-top: -70px;
  padding: 5rem 0 2rem;
  transition: 0.5s ease-in-out all;
}

/* SERVICES BY TYPE END */
.service2Shape {
  color: #fff;
  font-size: 2rem;
  background-color: #2e4d6b31;
  border-radius: 50px;
  padding: 2rem;
  opacity: 0.7;
  transition: 0.5s ease-in-out all;
}

.shape-industrial:hover {
  background-color: #f48c06 !important;
  color: #ededed;
}

.shape-hotelero:hover {
  background-color: rgb(255, 255, 255) !important;
  color: #ededed;
}

.shape-empresarial:hover {
  background-color: #3d74b8 !important;
  color: #ededed;
}

.shape-arq:hover {
  background-color: #e9ac3d !important;
  color: #ededed;
}

.shape-hogar:hover {
  background-color: #93abd8 !important;
  color: #ededed;
}

.service2Name {
  font-size: 0.8rem;
  line-height: 1.1rem;
  letter-spacing: 0.1em;
}
.services2Div {
  height: 50%;
}
.servicesDescription p {
  font-size: 0.8rem;
  letter-spacing: 0.1em;
  margin-bottom: 0;
  padding: 0.3rem 0;
}
.servicesDescription i {
  color: #528de5;
  color: var(--blue-cta);
  padding-right: 5px;
}
.servicePrimary {
  font-weight: 700;
}
.serviceSecondary {
  margin-left: 19px;
}

/* SOLUTIONS */

.solutionsDiv {
  position: relative;
  width: 100%;
}

@media (max-width: 767px) {
  .solutionsDiv {
    margin-top: 180px;
  }
}

@media (min-width: 768px) {
  .solutionsDiv {
    margin-top: 120px;
  }
}

@media (min-width: 1500px) {
  .solutionsDiv {
    margin-top: 150px;
  }
}
.solutionsText {
  position: absolute;
  z-index: 2;
}
.stepsDiv {
  height: 100vh;
}
.step {
  height: 80%;
}
.step:hover {
  height: 100%;
}
.step1 {
  background: linear-gradient(
      180deg,
      rgba(168, 168, 168, 0.85) 0%,
      rgba(168, 168, 168, 0.425) 100%
    ),
    url(/static/media/Paso1.d8b2d4d6.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position-x: center;
  background-size: 130%;
  transition: 0.8s ease-in all;
}
.step1:hover {
  background: linear-gradient(180deg, #2e496b 7.16%, rgba(30, 30, 30, 0.3) 100%),
    url(/static/media/Paso1.d8b2d4d6.jpg);
}
.step2 {
  background: linear-gradient(
      180deg,
      rgba(168, 168, 168, 0.85) 0%,
      rgba(168, 168, 168, 0.425) 100%
    ),
    url(/static/media/Paso2.a212faba.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position-x: center;
  background-size: 130%;
  transition: 0.8s ease-in all;
}
.step2:hover {
  background: linear-gradient(180deg, #2e496b 7.16%, rgba(30, 30, 30, 0.3) 100%),
    url(/static/media/Paso2.a212faba.jpg);
}
.step3 {
  background: linear-gradient(
      180deg,
      rgba(168, 168, 168, 0.85) 0%,
      rgba(168, 168, 168, 0.425) 100%
    ),
    url(/static/media/Paso3.813b624f.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position-x: center;
  background-size: 130%;
  transition: 0.8s ease-in all;
}
.step3:hover {
  background: linear-gradient(180deg, #2e496b 7.16%, rgba(30, 30, 30, 0.3) 100%),
    url(/static/media/Paso3.813b624f.jpg);
}
.step4 {
  background: linear-gradient(
      180deg,
      rgba(168, 168, 168, 0.85) 0%,
      rgba(168, 168, 168, 0.425) 100%
    ),
    url(/static/media/Paso4.5a178bc3.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position-x: center;
  background-size: 130%;
  transition: 0.8s ease-in all;
}
.step4:hover {
  background: linear-gradient(180deg, #2e496b 7.16%, rgba(30, 30, 30, 0.3) 100%),
    url(/static/media/Paso4.5a178bc3.jpg);
}
.stepNumber {
  font-size: 4rem;
}
.stepName {
  font-size: 1.3rem;
  line-height: 1.7rem;
  letter-spacing: 0.1em;
  width: 150px;
}
.stepDescription {
  position: relative;
  bottom: 0;
  font-size: 0.8rem;
  line-height: 1.1rem;
  letter-spacing: 0.1em;
  width: 90%;
  display: none;
}
.step:hover .stepDescription {
  display: flex;
}
.stepContainer {
  position: absolute;
  bottom: 0;
}

/* ABOUT */

.aboutDiv {
  background: linear-gradient(
      1.71deg,
      rgba(10, 29, 36, 0.7) 1.07%,
      rgba(255, 255, 255, 0) 98.19%
    ),
    url(/static/media/About.584d71f5.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 63% 52%;
  background-size: cover;
  height: 73%;
  width: 100%;
}
.aboutDiv1 {
  width: 100%;
}
.logoSyscom {
  width: 50%;
}

.unique-container-class {
  @media (min-width: 767px) {
    margin-top: 50px;
  }
}

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 14));
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 14));
  }
}
.slider {
  background: pr;
  height: auto;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.slider::before,
.slider::after {
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 80px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 50s linear infinite;
  animation: scroll 50s linear infinite;
  display: flex;
  align-items: center;
  width: calc(250px * 17);
}
.slider .slide {
  height: auto;
  width: 90%;
}
.brand {
  width: 45%;
  opacity: 0.7;
}

.aboutBrandDiv h4 {
  text-align: center;
}

/* CONTACT */
.contactDiv {
  background: linear-gradient(
      0.04deg,
      #0a1d24 0.2%,
      rgba(196, 196, 196, 0) 99.57%
    ),
    url(/static/media/Contacto.cd525303.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 50% 40%;
  background-size: 180%;
  position: relative;
}
.contactContainer {
  bottom: 0;
  position: absolute;
}
.checkListContainer {
  max-width: 30%;
}
.formContainer {
  background: #528de5b7;
  width: 60%;
  margin: 0.2rem 3rem 0;
}
.formContainer label {
  font-size: 0.8rem;
  line-height: 1.1rem;
  letter-spacing: 0.2em;
}
.formContainer input:focus {
  outline: none;
}
.formContainer div.formDirection i {
  font-size: 0.8rem;
}
.formContainer div.formDirection p {
  font-size: 0.8rem;
  line-height: 0.8rem;
  letter-spacing: 0.1em;
}
.btn-enviar {
  background: #2e496b;
  border-radius: 30px;
  color: #fff;
  font-size: 0.8rem;
  line-height: 1.1rem;
  text-align: center;
  letter-spacing: 0.2em;
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.btn-enviar:hover {
  background: #fff;
}
.checkList i {
  background-color: #528de5;
  color: #fff;
  font-size: 0.7rem;
  padding: 0.3rem;
  margin-right: 1.5rem;
  border-radius: 50px;
  border: 2px solid #528de5;
  border: 2px solid var(--blue-cta);
  transition: all 0.3s ease-in-out;
}
.checkList {
  max-width: 300px;
  transition: all 0.3s ease-in-out;
}
.checkDescription h4,
p {
  font-size: 0.8rem;
  line-height: 1.1rem;
  letter-spacing: 0.1em;
}
.checkListLine {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.46);
  width: 2px;
  height: 49.7px;
  left: 12px;
}
.checkListLine0 {
  top: 38px;
}
.checkListLine1 {
  top: 113px;
}
.checkListLine2 {
  top: 188px;
}
.checkListLine3 {
  top: 268px;
}

/* CLIENTS */
.clientsDiv {
  background-image: url(/static/media/Map.acc11b5a.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: 60%;
  margin-bottom: 20rem;
}

.clientsDiv ul {
  font-size: 0.7rem;
  letter-spacing: 0.1em;
}
.clientsDiv li {
  padding: 0.4rem 2rem;
  margin-bottom: 0.2rem;
  transition: 0.3s ease-in-out all;
}
.clientsDiv li:hover {
  background-color: rgba(82, 141, 229, 0.8);
}

/* AUTO HOVER */
.hover_check {
  background-color: rgba(82, 141, 229, 0.8);
  opacity: 0;
  position: relative;
}

.check_1 {
  -webkit-animation: auto-hover-one 3s 4s linear infinite;
          animation: auto-hover-one 3s 4s linear infinite;
}
.check_2 {
  -webkit-animation: auto-hover-two 3s 5s linear infinite;
          animation: auto-hover-two 3s 5s linear infinite;
}
.check_3 {
  -webkit-animation: auto-hover-two 3s 6s linear infinite;
          animation: auto-hover-two 3s 6s linear infinite;
}
.check_4 {
  -webkit-animation: auto-hover-one 3s 5s linear infinite;
          animation: auto-hover-one 3s 5s linear infinite;
}

@-webkit-keyframes auto-hover-one {
  0% {
    left: 20px;
    opacity: 0;
  }
  40% {
    left: 50px;
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  60% {
    left: 50px;
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    left: 80px;
    opacity: 0.3;
  }
  100% {
    left: 80px;
    opacity: 0;
  }
}

@keyframes auto-hover-one {
  0% {
    left: 20px;
    opacity: 0;
  }
  40% {
    left: 50px;
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  60% {
    left: 50px;
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    left: 80px;
    opacity: 0.3;
  }
  100% {
    left: 80px;
    opacity: 0;
  }
}

@-webkit-keyframes auto-hover-two {
  0% {
    left: 40px;
    opacity: 0;
  }
  40% {
    left: 70px;
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  60% {
    left: 70px;
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    left: 80px;
    opacity: 0.3;
  }
  100% {
    left: 80px;
    opacity: 0;
  }
}

@keyframes auto-hover-two {
  0% {
    left: 40px;
    opacity: 0;
  }
  40% {
    left: 70px;
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  60% {
    left: 70px;
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    left: 80px;
    opacity: 0.3;
  }
  100% {
    left: 80px;
    opacity: 0;
  }
}

.clientsDiv img.client {
  -webkit-filter: saturate(0);
          filter: saturate(0);
  transition: 0.3s ease-in-out all;
}
.clientsDiv img.client:hover {
  -webkit-filter: saturate(1);
          filter: saturate(1);
}
.client1,
.client2,
.client3,
.client4,
.client5,
.client11 {
  width: 16%;
}
.client6,
.client7,
.client8,
.client9,
.client10 {
  width: 7%;
}

.client12 {
  width: 12%;
}

.client13 {
  width: 20%;
}

.whiteClient:hover {
  -webkit-filter: contrast(0.2);
          filter: contrast(0.2);
}
.whiteClient {
  -webkit-filter: contrast(0.5);
          filter: contrast(0.5);
}

.clientwidth {
  border: 1px solid red;
}

/* BLOG */
.blogPage {
  background-color: rgba(46, 73, 107, 0.5);
  height: auto;
  flex: 1 1;
}
.blogDiv {
  background: linear-gradient(
      180deg,
      rgba(46, 73, 107, 0.5) 0%,
      rgba(46, 73, 107, 0) 100%
    ),
    url(/static/media/Blog.5c154e78.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center 40%;
  background-size: cover;
  height: 100%;
}
.blogDiv h5 {
  font-size: 1.1rem;
  line-height: 23px;
  letter-spacing: 0.03em;
  margin-bottom: 0;
}
.blogItem p {
  font-size: 0.7rem;
}
.blogItem {
  transition: 0.3s ease-in-out;
}
.blogItem:hover {
  cursor: pointer;
  background-color: #ffffff69 !important;
  color: #fff;
}
.readMore {
  color: #2e496b;
  transition: 0.3s ease-in-out;
}
.readMore:hover {
  color: #fff;
}
.readMore i {
  font-size: 0.6rem;
  margin-top: 0.1rem;
}

/* FOOTER */
.footerDiv {
  background-color: #0a1d24;
  height: auto;
  width: 100%;
  position: relative; /* Fija el footer en la parte inferior */
  bottom: 0; /* Coloca el footer al final de la pantalla */
  left: 0;
  z-index: 9999;
}
.copyright {
  background-color: rgba(82, 141, 229, 0.8);
  bottom: 0;
  font-size: 0.6rem !important;
  letter-spacing: 0.2rem;
  padding: 0.6rem 0;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
}
.footerTittle {
  font-size: 1rem;
  line-height: 23px;
  letter-spacing: 0.1em;
  margin-bottom: 4px;
}
.footerLine {
  background: #fcfcfc;
  height: 1vh;
  width: 25%;
}
.footerDiv div.rrss i {
  color: #0a1d24;
  background-color: #fff;
  padding: 6px;
  margin: 0 10px 0 0;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.footerDiv div.rrss i:hover {
  background-color: rgba(82, 141, 229, 0.8);
  color: #fff;
}
.fa-facebook-f {
  padding: 5px 8px !important;
}
.footerDiv img {
  width: 28%;
}
.footerDiv p {
  font-size: 0.7rem;
  letter-spacing: 0.1em;
  line-height: 1.1rem;
}
.footerDiv input {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 20px;
  transition: 0.3s ease-in-out;
}
.footerDiv input:hover {
  background-color: #fff;
}
.footerDiv input:focus {
  outline: none;
  background-color: #fff;
  border: 1px solid rgba(82, 141, 229, 0.8);
}
.footerDiv input::-webkit-input-placeholder {
  color: #fff;
  opacity: 0.6;
  font-size: 0.7rem;
  letter-spacing: 0.1em;
}
.footerDiv input:-ms-input-placeholder {
  color: #fff;
  opacity: 0.6;
  font-size: 0.7rem;
  letter-spacing: 0.1em;
}
.footerDiv input::-ms-input-placeholder {
  color: #fff;
  opacity: 0.6;
  font-size: 0.7rem;
  letter-spacing: 0.1em;
}
.footerDiv input::placeholder {
  color: #fff;
  opacity: 0.6;
  font-size: 0.7rem;
  letter-spacing: 0.1em;
}
.footerDiv button {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
  font-size: 0.7rem;
  letter-spacing: 0.03em;
  padding: 0.3rem 0.5rem;
  transition: 0.3s ease-in-out;
}
.footerDiv button:hover {
  background-color: rgba(82, 141, 229, 0.8);
  border: 1px solid rgba(82, 141, 229, 0.8);
  color: #fff;
}

/* WHATSAPP FACEBOOK MESSENGER Y MAIL*/
.rss {
  border-radius: 50px;
  height: 60px;
  position: fixed;
  width: 60px;
  z-index: 100;
}
.whats-app {
  bottom: 10.6%;
  right: 2.4%;
}
.messengerIcon {
  bottom: 17%;
  right: 1.5%;
}
.emailIcon {
  bottom: 0;
  right: 1.5%;
}
.contactItemsContainer:hover .my-float {
  -webkit-filter: blur(1px);
          filter: blur(1px);
  opacity: 0.5;
  transform: scale(0.98);
  transition: 0.2s ease-in-out;
}
.contactItemsContainer:hover .my-float:hover {
  transform: scale(1);
  -webkit-filter: blur(0px);
          filter: blur(0px);
  opacity: 1;
}

/* ADMIN */
.txt-navyblue {
  color: #1d416e;
}
.adminContainer {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url(/static/media/Login.0f40a1df.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: 110%;
  background-position: 10% center;
  height: 100vh;
  padding-top: 7rem;
  padding-bottom: 5rem;
}
.form-signin {
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin h1 {
  letter-spacing: 0.1rem;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-signin button {
  font-size: 0.8rem;
  padding: 0.8rem 0;
  letter-spacing: 0.2rem;
}
.img-container {
  background-color: #0a1d24;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
}
/* NAVBAR */
#adminNav {
  padding: 0 20rem;
}
#adminNav .nav-link {
  border-bottom: 3px solid #8b8b8b25;
  color: #8b8b8b;
  letter-spacing: 0.05em;
  padding-bottom: 0.2rem;
  transition: all 0.3s;
}
#adminNav .nav-link:hover {
  border-bottom: 3px solid #1d416e;
  color: #1d416e;
}
.nav-link-active {
  border-bottom: 3px solid #1d416e !important;
  color: #1d416e !important;
}
.logo-admin {
  width: 100%;
}
.navbar-brand {
  width: 80px;
}
/* CMS */
.bg-lightshadow {
  background-color: #ededed;
}
.admin-container {
  height: 100vh;
  padding-top: 7rem;
  padding-bottom: 5rem;
}
/*INICIO*/
.welcome {
  height: 45vh;
  width: 100%;
}
.welcome h1 {
  font-size: 2.5rem;
  width: 80%;
}
.welcome p {
  width: 60%;
}
.welcome a {
  text-decoration: none;
  color: #fff;
}

/*ADD POST*/
.add-post-title {
  font-weight: 900;
  font-size: 1.2rem;
  line-height: 20px;
  color: #1d416e;
}
.add-post-txt {
  font-size: 0.8rem;
  line-height: 0.8rem;
  letter-spacing: 0.03em;
  color: #313131;
}
.add-post-container ::-webkit-input-placeholder {
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
  color: #1d416e60;
}
.add-post-container :-ms-input-placeholder {
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
  color: #1d416e60;
}
.add-post-container ::-ms-input-placeholder {
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
  color: #1d416e60;
}
.add-post-container ::placeholder {
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
  color: #1d416e60;
}
.rdw-editor-toolbar {
  margin-bottom: 0 !important;
}

.rdw-editor-main {
  background-color: #ffffff !important;
  line-height: 5px;
  border: 1px solid #3131313d;
  height: 50vh;
  padding: 0 10px;
}
.rdw-emoji-wrapper {
  display: none !important;
}
.btn-rem {
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
}
.blog-post-container {
  background-color: #fff !important;
  font-family: "Fjalla One", sans-serif !important;
}
.editor-class figure {
  margin: 0;
}
.editor-class .rdw-image-left {
  display: unset;
  float: left;
}
.editor-class .rdw-image-right {
  display: unset;
  justify-content: unset;
  float: right;
}
/*EDIT POST*/
.btn-blue-line {
  background-color: #1d416e;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.btn-blue-line:hover {
  background: rgb(43, 43, 43);
  color: #fff;
  box-shadow: 0 8px 23px -1px #999;
}
/*POST LIST*/
.post-options span {
  color: #343434;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 0.8rem !important;
  letter-spacing: 0.15em;
}
.post-options i {
  color: #1d416e;
}
.post-options .btn-sm:hover {
  background-color: rgb(253, 253, 253);
  border: 1px solid #1d416e;
}
.post-entry {
  font-size: 0.8rem;
  letter-spacing: 0.15em;
  text-align: center;
}
.post-entry-container {
  height: auto;
}
.post-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  letter-spacing: 0.15em;
}
.num-counter {
  border-radius: 20px;
  border: 1px solid #1d416e;
  height: 25px;
  margin-left: 0.5rem;
  padding: 1px;
  text-align: center;
  width: 25px;
}
.table-row {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.ad-post-title {
  font-weight: 900;
  font-size: 0.7rem;
  line-height: 10px;
  letter-spacing: 0.15em;
  color: #1d416e;
  padding-bottom: 10px;
}
.ad-post-date {
  font-size: 0.7rem;
}
.ad-post-date span {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  line-height: 13px;
  letter-spacing: 0.15em;
  color: #313131;
}
/* COMMENT */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1040px) and (min-width: 600px) {
  section {
    height: auto;
  }
  .sectionPadding {
    padding: 5rem 5rem 1rem;
  }
  .sectionPadding1 {
    padding: 1rem 5rem 0;
  }
  .sectionPadding2 {
    padding: 5rem 5rem 0;
  }
  .paddingXaxis {
    padding: 5rem;
  }

  .sectionTitle {
    font-size: 2rem;
  }
  .sectionDescription {
    font-size: 0.8rem;
    line-height: 1.1rem;
    letter-spacing: 0.1em;
    width: 60%;
  }

  #mainNav {
    width: 100vw !important;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    border: none;
    background-color: transparent;
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  }
  .navbarBottomBorder {
    display: none;
  }
  .navbar-collapse {
    background-color: rgba(0, 0, 0, 0.638) !important;
    padding: 5px;
  }

  /*MASTERHEAD*/

  header {
    width: 100%;
  }

  header.masthead {
    background-position-x: 10%;
    background-size: cover;
    padding: 25rem 8rem 12.5rem;
  }
  header.masthead .masthead-subheading {
    font-size: 0.6rem;
  }
  header.masthead .masthead-heading {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3rem;
    letter-spacing: 0.03em;
    margin-bottom: 1.5rem;
  }
  header img {
    width: 60%;
  }
  .titleHighlighted {
    height: 50px;
  }

  /* INFO */

  #carouselExampleFade {
    height: 30vh;
  }
  .carousel-control-prev {
    width: 20%;
  }
  .carousel-control-next {
    width: 10%;
  }
  .infoCarousel {
    font-size: 2.2rem;
    line-height: 2.5rem;
  }
  .infoDiv h3 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  .infoDiv p {
    font-size: 0.5rem;
    line-height: 0.8rem;
    padding: 0 0.5rem;
  }
  .infoItem {
    opacity: 1;
  }

  /* TRANKS */

  .thanksDiv {
    background-position-x: 70%;
    background-size: cover;
    height: 50vh;
  }
  .thanksItem i {
    font-size: 2rem;
    padding: 2rem 1.7rem;
  }
  .thanksItem2 p {
    font-size: 0.6rem;
  }
  .thanksDescriptionDiv h4 {
    font-size: 1.1rem;
  }
  .thanksDescriptionDiv p {
    font-size: 0.6rem;
  }
  .thanksDescriptionDiv ul {
    font-size: 0.6rem;
    padding-left: 0;
  }

  /* SERVICES 1*/

  .services1 {
    -webkit-clip-path: polygon(50% 0%, 100% 20%, 100% 100%, 0 100%, 0% 20%);
            clip-path: polygon(50% 0%, 100% 20%, 100% 100%, 0 100%, 0% 20%);
    height: auto;
  }
  .serviceItem img {
    width: 50%;
  }
  .serviceItem h4 {
    margin: 0;
  }
  .serviceItem p {
    font-size: 0.6rem;
  }

  /* SERVICES 2*/
  .services2 {
    height: 28vh;
  }
  .services2Div {
    height: 25vh;
  }
  .services2Container h4 {
    font-size: 1.1rem;
    padding: 0.5rem 0;
  }
  .servicesDescription {
    height: auto;
  }
  .servicesDescription p {
    font-size: 0.5rem;
    padding: 0.1rem 0;
  }
  .service2Shape {
    font-size: 1.8rem;
    padding: 1.5rem;
  }
  .service2Name {
    font-size: 0.6rem;
  }

  /* SOLUTIONS */
  .solutionsDiv {
    position: relative;
  }
  .solutionsText {
    position: absolute;
    z-index: 2;
  }
  .stepsDiv {
    height: 50vh;
  }
  .step {
    height: 100%;
  }
  .step1 {
    background-size: cover;
    height: 100%;
  }
  .step2 {
    background-position-y: center;
    background-size: cover;
    height: 100%;
  }
  .step3 {
    background-position-y: 78%;
    background-size: cover;
    height: 100%;
  }
  .step4 {
    background-position-y: 8%;
    background-size: cover;
    height: 100%;
  }
  .stepNumber {
    font-size: 3rem;
  }
  .stepName {
    font-size: 1rem;
    line-height: 1rem;
    padding-left: 4px;
    padding-right: 0;
    margin: 0;
    width: 150px;
  }
  .stepDescription {
    display: flex;
    width: 170px;
    padding: 0;
    font-size: 0.49rem;
    line-height: 1rem;
  }
  .stepContainer {
    display: flex;
    flex-direction: column;
    width: 150px;
  }
  .step1:hover {
    background: linear-gradient(
        180deg,
        #2e496b 7.16%,
        rgba(30, 30, 30, 0.3) 100%
      ),
      url(/static/media/Paso1.d8b2d4d6.jpg);
    background-size: cover;
    height: 100%;
  }
  .step2:hover {
    background: linear-gradient(
        180deg,
        #2e496b 7.16%,
        rgba(30, 30, 30, 0.3) 100%
      ),
      url(/static/media/Paso2.a212faba.jpg);
    background-position-y: center;
    background-size: cover;
    height: 100%;
  }
  .step3:hover {
    background: linear-gradient(
        180deg,
        #2e496b 7.16%,
        rgba(30, 30, 30, 0.3) 100%
      ),
      url(/static/media/Paso3.813b624f.jpg);
    background-position-y: 78%;
    background-size: cover;
    height: 100%;
  }
  .step4:hover {
    background: linear-gradient(
        180deg,
        #2e496b 7.16%,
        rgba(30, 30, 30, 0.3) 100%
      ),
      url(/static/media/Paso4.5a178bc3.jpg);
    background-position-y: 8%;
    background-size: cover;
    height: 100%;
  }

  /* ABOUT */
  .aboutDiv {
    background-position-x: 43%;
    background-size: cover;
    height: 50vh;
  }
  .aboutBrandDiv h4 {
    font-size: 1.1rem;
  }
  .logoSyscom {
    width: 60%;
  }
  .aboutBrandDiv {
    height: 20vh;
  }

  /* CONTACT */
  .contactDiv {
    background-position-x: 55%;
    background-size: cover;
    height: auto;
  }
  .contactContainer {
    position: relative;
    bottom: 0;
  }
  .checkList {
    opacity: 1;
    width: 300px;
  }
  .formContainer {
    position: relative;
    background: #528de5b7;
    width: 100%;
    margin: 0.2rem 0 0;
  }

  /* CLIENTS */
  .clientsDiv {
    height: auto;
    margin-bottom: 0rem;
  }
  .clientsDiv h4 {
    font-size: 1.1rem;
  }
  .clientsDiv ul {
    font-size: 0.6rem;
  }
  .clientsDiv li {
    padding: 0;
  }
  .clientsDiv img.client {
    opacity: 0.8;
  }
  @-webkit-keyframes auto-hover-one {
    0% {
      left: 0px;
      opacity: 0;
    }
    40% {
      left: 20px;
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    60% {
      left: 20px;
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      left: 35px;
      opacity: 0.3;
    }
    100% {
      left: 35px;
      opacity: 0;
    }
  }
  @keyframes auto-hover-one {
    0% {
      left: 0px;
      opacity: 0;
    }
    40% {
      left: 20px;
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    60% {
      left: 20px;
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      left: 35px;
      opacity: 0.3;
    }
    100% {
      left: 35px;
      opacity: 0;
    }
  }

  @-webkit-keyframes auto-hover-two {
    0% {
      left: 15px;
      opacity: 0;
    }
    40% {
      left: 30px;
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    60% {
      left: 30px;
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      left: 35px;
      opacity: 0.3;
    }
    100% {
      left: 35px;
      opacity: 0;
    }
  }

  @keyframes auto-hover-two {
    0% {
      left: 15px;
      opacity: 0;
    }
    40% {
      left: 30px;
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    60% {
      left: 30px;
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      left: 35px;
      opacity: 0.3;
    }
    100% {
      left: 35px;
      opacity: 0;
    }
  }

  /* BLOG */
  .blogDiv {
    height: 30vh;
  }
  .blogItem {
    margin-bottom: 10px;
  }
  .blogDiv h5 {
    font-size: 0.8rem;
    line-height: 12px;
  }
  /* FOOTER */
  .footerDiv {
    height: auto;
  }
  .copyDiv {
    position: relative;
  }
  .copyright {
    font-size: 0.5rem;
    letter-spacing: 0.08rem;
  }
  .footerTittle {
    font-size: 0.8rem;
  }
  .footerLine {
    height: 0.5vh;
    width: 45%;
  }
  .footerDiv input {
    height: 30px;
    padding: 0.3rem;
    text-align: left;
    width: 100%;
  }
  .footerDiv button {
    font-size: 0.5rem;
    padding: 0.5rem;
  }
  .footerDiv p {
    font-size: 0.5rem;
    line-height: 0.8rem;
  }

  /* WHATSAPP FACEBOOK MESSENGER Y MAIL*/
  .rss {
    height: 40px;
    width: 40px;
  }
  .whats-app {
    bottom: 6.5%;
    right: 4.5%;
  }
  .messengerIcon {
    bottom: 10%;
    right: 3%;
  }
  .emailIcon {
    bottom: 1%;
    right: 3%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  section {
    height: auto;
  }
  .sectionPadding {
    padding: 5rem 2rem 1rem;
  }
  .sectionPadding1 {
    padding: 1rem 2rem 0;
  }
  .sectionPadding2 {
    padding: 5rem 2rem 0;
  }
  .paddingXaxis {
    padding: 1rem;
  }

  .sectionTitle {
    font-size: 1.5rem;
  }
  .sectionDescription {
    font-size: 0.6rem;
    line-height: 1.1rem;
    letter-spacing: 0.1em;
    width: 100%;
  }

  #mainNav {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    border: none;
    background-color: transparent;
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  }

  .navbarBottomBorder {
    display: none;
  }

  .btn-custom {
    font-size: 0.6rem;
    letter-spacing: 0.1em;
    padding: 0.5rem 1.2rem;
  }

  .navbar-collapse {
    background-color: rgba(0, 0, 0, 0.638) !important;
    padding: 5px;
  }

  /*MASTERHEAD*/
  header.masthead {
    background-position-x: 10%;
    background-size: cover;
  }
  header.masthead {
    padding: 15rem 2rem 12.5rem;
  }
  header.masthead .masthead-subheading {
    font-size: 0.6rem;
  }
  header.masthead .masthead-heading {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.03em;
    margin-bottom: 1.5rem;
  }
  header img {
    width: 70%;
  }
  .titleHighlighted {
    height: 32px;
  }

  /* INFO */
  #carouselExampleFade {
    height: 50vh;
  }
  .carousel-control-prev {
    width: 10%;
  }
  .carousel-control-next {
    width: 10%;
  }
  .infoCarousel {
    font-size: 1.8rem;
    line-height: 2rem;
  }
  .infoDiv h3 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  .infoDiv p {
    font-size: 0.5rem;
    line-height: 0.8rem;
    padding: 0 0.5rem;
  }
  .infoItem {
    opacity: 1;
  }

  /* TRANKS */

  .thanksDiv {
    background-position-x: 70%;
    background-size: cover;
    height: 50vh;
  }
  .thanksItem i {
    font-size: 2rem;
    padding: 2rem 1.7rem;
  }
  .thanksItem2 p {
    font-size: 0.6rem;
  }
  .thanksDescriptionDiv h4 {
    font-size: 1.1rem;
  }
  .thanksDescriptionDiv p {
    font-size: 0.6rem;
  }
  .thanksDescriptionDiv ul {
    font-size: 0.6rem;
    padding-left: 0;
  }

  /* SERVICES 1*/

  .services1 {
    -webkit-clip-path: polygon(50% 0%, 100% 5%, 100% 100%, 0 100%, 0% 5%);
            clip-path: polygon(50% 0%, 100% 5%, 100% 100%, 0 100%, 0% 5%);
    height: auto;
  }
  .serviceItem img {
    width: 20%;
  }
  .serviceItem h4 {
    margin: 0;
  }
  .serviceItem p {
    font-size: 0.6rem;
  }

  /* SERVICES 2*/
  .services2 {
    height: auto;
  }
  .services2Div {
    height: 35vh;
  }
  .services2Container h4 {
    font-size: 1.1rem;
    padding: 0.5rem 0;
  }
  .servicesDescription {
    height: auto;
  }
  .servicesDescription p {
    font-size: 0.5rem;
    padding: 0.1rem 0;
  }
  .service2-active .service2Name {
    margin-top: -48px;
    padding: 3rem 0 0;
  }
  /* SOLUTIONS */
  .solutionsDiv {
    position: relative;
    width: 100%;
    margin-bottom: 4rem;
  }
  .solutionsText {
    position: absolute;
    z-index: 2;
  }
  .step {
    height: auto;
  }
  .step1 {
    background-size: cover;
    height: 50%;
  }
  .step2 {
    background-position-y: center;
    background-size: cover;
    height: 20%;
  }
  .step3 {
    background-position-y: 78%;
    background-size: cover;
    height: 20%;
  }
  .step4 {
    background-position-y: 8%;
    background-size: cover;
    height: 20%;
  }
  .stepNumber {
    font-size: 3rem;
  }
  .stepName {
    font-size: 1.1rem;
    padding-left: 4px;
  }
  .stepDescription {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 0.6rem;
  }
  .stepContainer {
    display: flex;
    flex-direction: row;
  }
  .step1:hover {
    background: linear-gradient(
        180deg,
        #2e496b 7.16%,
        rgba(30, 30, 30, 0.3) 100%
      ),
      url(/static/media/Paso1.d8b2d4d6.jpg);
    background-size: cover;
    height: 50%;
  }
  .step2:hover {
    background: linear-gradient(
        180deg,
        #2e496b 7.16%,
        rgba(30, 30, 30, 0.3) 100%
      ),
      url(/static/media/Paso2.a212faba.jpg);
    background-position-y: center;
    background-size: cover;
    height: 20%;
  }
  .step3:hover {
    background: linear-gradient(
        180deg,
        #2e496b 7.16%,
        rgba(30, 30, 30, 0.3) 100%
      ),
      url(/static/media/Paso3.813b624f.jpg);
    background-position-y: 78%;
    background-size: cover;
    height: 20%;
  }
  .step4:hover {
    background: linear-gradient(
        180deg,
        #2e496b 7.16%,
        rgba(30, 30, 30, 0.3) 100%
      ),
      url(/static/media/Paso4.5a178bc3.jpg);
    background-position-y: 8%;
    background-size: cover;
    height: 20%;
  }

  /* ABOUT */
  .aboutDiv {
    background-position-x: 43%;
    background-size: cover;
    height: 70vh;
  }
  .aboutBrandDiv h4 {
    font-size: 1.1rem;
  }
  .logoSyscom {
    width: 100%;
  }
  .aboutBrandDiv {
    height: 30vh;
  }

  /* CONTACT */
  .contactDiv {
    background-position-x: 55%;
    background-size: cover;
    height: auto;
  }
  .contactContainer {
    position: relative;
    bottom: 0;
  }
  .checkList {
    opacity: 1;
    width: 300px;
  }
  .formContainer {
    position: relative;
    background: #528de5b7;
    width: 100%;
    margin: 0.2rem 0 0;
  }
  /* CLIENTS */
  .clientsDiv {
    height: auto;
    margin-bottom: 0rem;
  }
  .clientsDiv h4 {
    font-size: 1.1rem;
  }
  .clientsDiv ul {
    font-size: 0.6rem;
  }
  .clientsDiv li {
    padding: 0;
  }
  .clientsDiv img.client {
    opacity: 0.8;
  }
  @-webkit-keyframes auto-hover-one {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 0.3;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes auto-hover-one {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 0.3;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes auto-hover-two {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 0.3;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes auto-hover-two {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 0.3;
    }
    100% {
      opacity: 0;
    }
  }

  /* BLOG */
  .blogDiv {
    height: auto;
  }
  .blogPage {
    height: auto;
  }
  .blogDiv {
    height: auto;
  }
  .blogItem {
    margin-bottom: 10px;
  }

  /* FOOTER */
  .footerDiv {
    height: auto;
  }
  .copyDiv {
    position: relative;
  }
  .copyright {
    /* background: red; */
    letter-spacing: 0.08rem;
    font-size: 0.5rem;
  }
  .footerTittle {
    font-size: 0.8rem;
  }
  .footerLine {
    width: 15%;
    height: 0.5vh;
  }
  .footerDiv input {
    width: 100%;
    padding: 0.3rem 0.5rem;
    text-align: center;
  }
  .footerDiv button {
    width: 100%;
    padding: 0.5rem;
  }
  .footerDiv p {
    font-size: 0.6rem;
  }

  /* WHATSAPP FACEBOOK MESSENGER Y MAIL*/
  .rss {
    height: 40px;
    width: 40px;
  }
  .whats-app {
    bottom: 10%;
    right: 10%;
  }
  .messengerIcon {
    bottom: 15%;
    right: 7%;
  }
  .emailIcon {
    bottom: 1%;
    right: 7%;
  }
  .anima_up2 {
    transition: all 0.8s;
  }
  .mostrar_up2 {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
}

.anima_up {
  opacity: 0;
  transition: all 0.8s;
}
.mostrar_up {
  -webkit-animation-name: mostrarArriba;
          animation-name: mostrarArriba;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
@-webkit-keyframes mostrarArriba {
  0% {
    transform: translateY(60px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes mostrarArriba {
  0% {
    transform: translateY(60px);
  }
  100% {
    transform: translateY(0);
  }
}

.anima_up2 {
  opacity: 0;
  transition: all 3s;
}
.mostrar_up2 {
  -webkit-animation-name: mostrarArriba;
          animation-name: mostrarArriba;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.anima_right {
  opacity: 0;
  transition: all 0.8s;
}
.mostrar_right {
  -webkit-animation-name: mostrarDerecha;
          animation-name: mostrarDerecha;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
@-webkit-keyframes mostrarDerecha {
  0% {
    transform: translateX(60px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes mostrarDerecha {
  0% {
    transform: translateX(60px);
  }
  100% {
    transform: translateX(0);
  }
}

.anima_right2 {
  opacity: 0;
  transition: all 3s;
}
.mostrar_right2 {
  -webkit-animation-name: mostrarDerecha;
          animation-name: mostrarDerecha;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.anima_right3 {
  opacity: 0;
  transition: all 3s;
}
.mostrar_right3 {
  -webkit-animation-name: mostrarDerecha;
          animation-name: mostrarDerecha;
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s;
}

.anima_right4 {
  opacity: 0;
  transition: all 3s;
}
.mostrar_right4 {
  -webkit-animation-name: mostrarDerecha;
          animation-name: mostrarDerecha;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}
@media only screen and (min-width: 1400px) {
  section.contactDiv.limitHeight {
    max-height: 850px;
  }
}

